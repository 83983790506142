var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	var createInstances = function (parentElement) {
		var dateInputs = parentElement.querySelectorAll("[data-datepicker]");

		Sushi.Dom.forEach(dateInputs, function (inputElement) {
			var dayOfWeek = window.TheDatepicker.DayOfWeek;
			var datepicker = new window.TheDatepicker.Datepicker(inputElement);
			var options = datepicker.options;
			var translator = options.translator;

			options.setInputFormat("d/m/Y");
			options.setFirstDayOfWeek(dayOfWeek.Sunday);
			translator.setDayOfWeekTranslation(dayOfWeek.Monday, "M");
			translator.setDayOfWeekTranslation(dayOfWeek.Tuesday, "T");
			translator.setDayOfWeekTranslation(dayOfWeek.Wednesday, "W");
			translator.setDayOfWeekTranslation(dayOfWeek.Thursday, "T");
			translator.setDayOfWeekTranslation(dayOfWeek.Friday, "F");
			translator.setDayOfWeekTranslation(dayOfWeek.Saturday, "S");
			translator.setDayOfWeekTranslation(dayOfWeek.Sunday, "S");

			options.onSelect(function () {
				Sushi.getPluginInstance(Plugins.FloatingLabel, inputElement).update();
			});

            datepicker.render();
		});
	};

	createInstances(document);



	/* Repeaters
	 * --------------------------- */

	Sushi.Events(document).on("Repeater.add", function (event) {
		createInstances(event.target);
	});
})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));
