/* ==============================================================================================
 * NEWSLETTER SIGN UP COMPONENT
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var Dom = Sushi.Dom;
	var Events = Sushi.Events;
	var Marketo = window.MktoForms2;

	Marketo.loadForm("https://app-ab15.marketo.com", "565-BDO-100", 6574);

	Marketo.whenReady(function (form) {
		var formElement = form.getFormElem()[0];

		removeMarketoLinkTags();
		removeFormStyleTag(formElement);
		hideUnecessaryElements(formElement);
		addBasicFrameworkClasses(formElement);
		parseInputTags(formElement);
		parseSelectTags(formElement);
		addRequiredFieldClasses(formElement);
		removeEmptyLayoutItems(formElement);
		styleFormFooter(formElement);
	});

	function removeMarketoLinkTags() {
		document.getElementById("mktoForms2BaseStyle").remove();
		document.getElementById("mktoForms2ThemeStyle").remove();
	}

	function removeFormStyleTag(formElement) {
		formElement.querySelector("style").remove();
	}

	function addBasicFrameworkClasses(formElement) {
		formElement.classList.add("o-layout");
		formElement.classList.add("_auto");
		formElement.classList.add("_ffbase");

		var classMatrix = {
			mktoFieldWrap: "c-input",
			mktoField: "c-input__field",
			mktoFieldDescriptor: "_mb0",
		};

		Object.keys(classMatrix).forEach(function (marketoClassName) {
			var elements = formElement.getElementsByClassName(marketoClassName);

			Dom.forEach(elements, function (element) {
				element.classList.add(classMatrix[marketoClassName]);
			});
		});

		var layoutItemElements = formElement.querySelectorAll(".mktoFormRow, .mktoButtonRow");

		Dom.forEach(layoutItemElements, function (element) {
			element.classList.add("o-layout__item");
			element.classList.add("_1/2@tabletH");
			element.classList.add("_pb");
		});
	}

	function parseInputTags(formElement) {
		Dom.forEach(formElement.querySelectorAll("input.c-input__field"), function (element) {
			var container = element.closest(".c-input");

			if (!container) {
				return;
			}

			container.classList.add("c-input--box");
			element.classList.add("_1/1");

			updateLabelValue(container);
			registerPlaceholderListeners(container);
		});
	}

	function updateLabelValue(inputComponentElement) {
		var label = inputComponentElement.querySelector("label");

		label.innerHTML = label.innerText.replace(/[*:]/g, "");

		label.classList.remove("_dn");
		label.classList.add("c-input__placeholder");
		label.classList.add("_auto");
	}

	function registerPlaceholderListeners(inputComponentElement) {
		var inputElement = inputComponentElement.querySelector(".c-input__field");

		if (!inputElement) {
			return;
		}

		Events(inputElement).on("input focus blur", function () {
			if (inputElement.value !== "") {
				inputComponentElement.classList.add("is-filled");
			}
			else {
				inputComponentElement.classList.remove("is-filled");
			}
		});

		Events(inputElement).on("focus blur", function (event) {
			if (event.type === "focus") {
				inputComponentElement.classList.add("is-active");
			}
			else {
				inputComponentElement.classList.remove("is-active");
			}
		});
	}

	function parseSelectTags(formElement) {
		Dom.forEach(formElement.querySelectorAll("select.c-input__field"), function (element) {
			element.closest(".c-input").classList.add("c-input--select");

			updateFirstOptionLabel(element);

			new Sushi.Plugins.Select(element, {
				hideNull: true,
			});
		});
	}

	function updateFirstOptionLabel(selectElement) {
		var parent = selectElement.closest(".c-input");
		var label = parent.querySelector("label");

		selectElement.getElementsByTagName("option")
			.item(0)
			.innerHTML = label.innerText.replace(/[*:]/g, "");
	}

	function addRequiredFieldClasses(formElement) {
		Dom.forEach(formElement.getElementsByClassName("mktoRequired"), function (element) {
			var parent = element.closest(".c-input");

			if (!parent) {
				return;
			}

			parent.classList.add("c-input--required");
		});
	}

	/**
	 * Remove empty layout__items (as hidden fields also use mktoField classes)
	 *
	 * @param formElement
	 */
	function removeEmptyLayoutItems(formElement) {
		var emptyElements = formElement.querySelectorAll("input[type='hidden'], .mktoPlaceholder");

		Dom.forEach(emptyElements, function (element) {
			var layoutItem = element.closest(".o-layout__item");

			if (!layoutItem) {
				return;
			}

			layoutItem.classList.add("_dn");
		});
	}

	function hideUnecessaryElements(formElement) {
		var elementsToHide = [
			".mktoClear",
			".mktoErrorArrowWrap",
			".mktoGutter",
			".mktoLabel",
			".mktoOffset",
		].join(",");

		Dom.forEach(formElement.querySelectorAll(elementsToHide), function (element) {
			element.classList.add("_dn");
		});
	}

	function styleFormFooter(formElement) {
		var htmlElement = formElement.querySelector(".mktoHtmlText");
		var buttonWrap = formElement.querySelector(".mktoButtonWrap");
		var buttonElement = formElement.querySelector(".mktoButton");
		var submitButtonIconTemplate = document.getElementById("submitButtonIcon").innerHTML;

		htmlElement.classList.add("_auto");
		htmlElement.classList.add("_fz14");

		buttonWrap.classList.add("_m0");

		var buttonClasses = [
			"c-btn",
			"c-btn--red",
			"_ttu",
			"_white",
			"_1/1",
			"_lh-copy",
			"_df",
			"_aic",
			"_jcsb",
		];

		buttonClasses.forEach(function (className) {
			buttonElement.classList.add(className);
		});

		buttonElement.innerHTML = buttonElement.innerHTML + submitButtonIconTemplate;
	}
})(Sushi || (Sushi = {}));
