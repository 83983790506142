/* ==============================================================================================
 * REVEAL CHECKBOX TOGGLER
 *
 * This is a helper to make checkboxes toggle reveals
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var toggleElementsContainers = document.querySelectorAll("[data-toggle]");

	Sushi.Dom.forEach(toggleElementsContainers, function (container) {
		var toggle = container.querySelector("input[type=checkbox]");

		if (!toggle) {
			return;
		}

		var reveal = new Sushi.Plugins.Reveal(document.createElement("i"), {
			target: document.getElementById(container.dataset.toggle),
		});

		var Events = Sushi.Events;

		var update = function () {
			var inverted = (container.dataset.toggleInvert !== void 0);

			if ((toggle.checked && !inverted) || (!toggle.checked && inverted)) {
				reveal.open();
			}
			else {
				reveal.close();
			}
		};

		Events(toggle).on("change", update);

		update();
	});
})(Sushi || (Sushi = {}));
