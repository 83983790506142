/* ==============================================================================================
 * FLOATING LABEL SELECT
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	var FloatingLabel = Plugins.FloatingLabel;
	var Events = Sushi.Events;
	var Select = Plugins.Select;

	var FloatingLabelSelect = function (triggerElement) {
		Events(triggerElement).on("*.Select.aftercreate", function (event) {
			if (triggerElement.hasAttribute("required")) {
				event.detail.select.containerElement.classList.add("c-input--required");
			}
		});

		new Select(triggerElement);
		var floatingLabel = new FloatingLabel(triggerElement);

		Events(triggerElement).on("Select.change", function () {
			floatingLabel.update();
		});
	};

	FloatingLabelSelect.displayName = "FloatingLabelSelect";

	FloatingLabelSelect.prototype = Object.create(Select.prototype);

	var proto = FloatingLabelSelect.prototype;

	proto.constructor = FloatingLabelSelect;

	Plugins.FloatingLabelSelect = FloatingLabelSelect;
})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));
