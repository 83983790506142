/* ==============================================================================================
 * INITIALIZE APP
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	/**
	 * Detects and loads fallback images
	 */
	function loadFallbackImages() {
		var fallbackImages = document.querySelectorAll(".c-fallbackImage");

		Array.prototype.forEach.call(fallbackImages, function (image) {

			image.setAttribute("src", image.getAttribute("data-src"));
			image.classList.remove("_dn");
		});
	}

	/**
	 * IF browser is IE or Edge add class to html tag and load fallback images
	 */
	if (Sushi.Util.detectIe()) {
		document.getElementsByTagName("html")[0].classList.add("ie");

		loadFallbackImages();
	}

})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));

var permissionForm = document.querySelectorAll("[name=requestPermission]");
var stepperInstance = Sushi.getPluginInstance("Stepper", document.querySelector("[data-plugin=Stepper]"));

if (permissionForm && stepperInstance) {
	for (var i = 0, n = permissionForm.length; i < n; i++) {
		permissionForm[i].addEventListener("click", function (event) {
			event.currentTarget.value === "grant"
				? stepperInstance.toggleLastStep(true)
				: stepperInstance.toggleLastStep(false);
		});
	}
}
