/* ==============================================================================================
 * FULL ADDRESS COMPONENT FEATURE
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var adrGdrSelector = document.querySelector("[data-input='adrGdr'] select");
    var stateContainer = document.querySelector("[data-input='adrGdrState'] .c-select");

	var Events = Sushi.Events;

    if (!adrGdrSelector || !stateContainer) {
        return;
    }

    var stateSelector = stateContainer.querySelector("select");

    var update = function () {
        console.log(adrGdrSelector.selectedOptions[0].value);
        if (adrGdrSelector.selectedOptions[0].value === "ADR" || adrGdrSelector.selectedOptions[0].value === "GDR") {
            stateContainer.classList.remove("c-input--required");
            stateSelector.required = false;
        }
        else {
            stateContainer.classList.add("c-input--required");
            stateSelector.required = true;
        }
    };

    update();

    Events(adrGdrSelector).on("Select.change", update);
})(Sushi || (Sushi = {}));
