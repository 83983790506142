/* ==============================================================================================
 * FULL ADDRESS COMPONENT FEATURE
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var Dom = Sushi.Dom;
	var Events = Sushi.Events;


	/**
	 * Country and state
	 */

	var elements = document.querySelectorAll("[data-feature='fullAddress']");

	Dom.forEach(elements, function (element) {
		var countrySelector = element.querySelector("[data-input='country'] select");
		var stateSelectContainer = element.querySelector("[data-input='stateSelect']");
		var stateTextContainer = element.querySelector("[data-input='stateText']");

		if (!countrySelector || !stateSelectContainer || !stateTextContainer) {
			return;
		}

		var update = function () {
			if (countrySelector.selectedOptions[0].value === "United States") {
				stateSelectContainer.classList.remove("_dn");
				stateTextContainer.classList.add("_dn");
			}
			else {
				stateSelectContainer.classList.add("_dn");
				stateTextContainer.classList.remove("_dn");
			}
		};

		update();

		Events(countrySelector).on("Select.change", update);
	});


	/**
	 * Zipcode
	 */

	elements = document.querySelectorAll("[data-input='zipcode']");

	var zipcodeErrorMessage = "This field must be a 5 or 9 digit U.S. ZIP Code (like 94043). Please reenter it now.";
	var zipcodeDelimeter = "-";
	var validZipcodeLengths = [5, 9];

	/**
	 * Formats the zipcode
	 * Takes a string of 5 or 9 digits and if 9 digits, inserts separator hyphen
	 * @param {string} zipcode 
	 * @returns {string} formatted zipcode
	 */
	function formatZIPCode(zipcode) {
		if (zipcode.length == 5) {
			return zipcode;
		}

		return zipcode.substr(0, 5) + zipcodeDelimeter + zipcode.substr(5);
	}

	/**
	 * Validades the zipcode
	 * @param {string} zipcode 
	 * @param {boolean} acceptsEmpty if the input can be left empty
	 * @return {boolean} returns true if the value is valid
	 */
	function isZipcodeValid(input, required) {
		if (required  === undefined) {
			required = false;
		}

		if (required !== true && !input.value) {
			return true;
		}
		
		var normalizedZipcode = input.value.replace(/-| /gi, "");

		if (isNaN(Number(normalizedZipcode)) || validZipcodeLengths.indexOf(normalizedZipcode.length) === -1) {
			alert(zipcodeErrorMessage);

			setTimeout(function () {
				input.select();
				input.focus();
			}, 1);

			return false;
		}
		else {
			input.value = formatZIPCode(normalizedZipcode);

			return true;
		}
	}

	Dom.forEach(elements, function (element) {
		var zipcodeInput = element.querySelector("input");

		if (!zipcodeInput) {
			return;
		}

		/**
		 * Validates the zipcode
		 */
		function validateZipcode() {
			isZipcodeValid(zipcodeInput, zipcodeInput.hasAttribute("required"));
		}

		Events(zipcodeInput).on("Select.blur", validateZipcode);
	});
})(Sushi || (Sushi = {}));
